import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyFormTextInput = lazy(
  () => import("./form-text-input-CTfxwe9B.js").then((module) => ({
    default: module.FormTextInput
  }))
);
const LazyTextInput = lazy(
  () => import("./text-input-D7tb_mMP.js").then((n) => n.t).then((module) => ({
    default: module.TextInput
  }))
);
function FormTextInput({
  uuid: _uuid,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyFormTextInput, { ...props });
}
const useFormTextInputContent = () => {
  return useMemo(
    () => ({
      formText: {
        component: FormTextInput
      }
    }),
    []
  );
};
export {
  LazyFormTextInput as FormTextInput,
  LazyTextInput as TextInput,
  useFormTextInputContent
};
